import {
  USER_DATA,
  IS_DARK,
  IS_TOGGLE,
  ACCESS_MODULE,
  SIDEBAR_TOGGLE,
  DELETE_LENGTH,
  SIDEBAR_TOGGLE_RESP,
  IS_MODAL,
  ACCESS_TOKEN,
  COURSE_ID,
  IS_MUTED,
  SIDE_CONTENT_DATA,
  EPISODEID,
  COMPLETEPROGRESS,
  VIDEOPLAY,
  VIDEOCOUNT,
  BANNERTHUMB,
  EPISODECOMPLETED,
  EPISODEREFRESH,
  BUTTONNAME,
  QUIZEPISODE,
  MENUREFRESH,
  LANGUAGEID,
  SELECTLANG,
  SELECTLANGJSON,
  LANGUAGES,
  EPISODELANGUAGES,
  EPISODESELECTLANGUAGE,
  PROFILEUPDATE,
  EPISODELENGTH,
  TEMPLATEID,
  ISACTIONDISABLED,
  PROFILEDATA,
  ONBOARDING,
  COURSEMENUDATA,
} from "./actionTypes";
import englishLang from "../utils/Languages/english.json";
import { template } from "lodash";
import { decryptData } from "../utils/Helpers";
import CryptoJS from "crypto-js";
const secretKeyMain = "rmLearnerSecretKey";

let theme = localStorage.rainLearnerTheme || true;
const encryptedAccessToken = localStorage.getItem("rainLearnerToken");
// let access_token = localStorage.rainClientToken?.toString();
let access_token = null;
if (encryptedAccessToken) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedAccessToken, secretKeyMain);
    access_token = bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Access token decryption error:", error);
  }
}
let encryptedRainCourseId = localStorage.getItem("rainLearnerCourseId");
let rainCourseId = null;
if (encryptedRainCourseId) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedRainCourseId, secretKeyMain);
    rainCourseId = bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Access token decryption error:", error);
  }
}
let rainIsMuted = localStorage.rainIsMuted;
let volumeValue = localStorage.rainLearnerVolume || 0.5;
let languages = decryptData(localStorage.getItem("languagesObj")) || [];
let encryptedLearnerLang = window.localStorage.getItem("rainLearnerLang");
let rainLearnerLang = null;
if (encryptedLearnerLang) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedLearnerLang, secretKeyMain);
    rainLearnerLang = bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Access token decryption error:", error);
  }
}
let isActionDisabled =
  window.localStorage.getItem("isActionDisabled") === "true";
let userDataLearner =
  decryptData(localStorage.getItem("rainLearnerUserData")) || {};

const initialState = {
  isDark: theme,
  isSidebarToggle: false,
  deleteLength: [],
  sidebarIsOpen: false,
  isModal: false,
  access_token: access_token,
  courseId: rainCourseId,
  rainIsMuted: rainIsMuted,
  sidebarContentData: null,
  episodeId: null,
  completeProgress: 0,
  videoPlay: false,
  videoCount: 0,
  bannerThumb: {},
  episodeCompleted: null,
  episodeRefresh: 0,
  volumeValue: volumeValue,
  buttonName: "",
  quizEpisode: false,
  menuRefresh: 0,
  languageId: "",
  selectLang: rainLearnerLang,
  selectLangJson: {},
  languages: languages,
  episodeLanguages: [],
  episodeSelectLang: "",
  profileUpdate: 1,
  episodeLength: [],
  templateId: "",
  isActionDisabled: isActionDisabled,
  profileData: userDataLearner,
  onBoarding: false,
};

const customReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case USER_DATA:
      return {
        ...state,
        userData: data,
      };
    case IS_DARK:
      return {
        ...state,
        isDark: data,
      };
    case IS_TOGGLE:
      return {
        ...state,
        isToggle: data,
      };
    case ACCESS_MODULE:
      return {
        ...state,
        accessModule: data,
      };
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        isSidebarToggle: data,
      };
    case DELETE_LENGTH:
      return {
        ...state,
        deleteLength: data,
      };
    case SIDEBAR_TOGGLE_RESP:
      return {
        ...state,
        sidebarIsOpen: data,
      };
    case IS_MODAL:
      return {
        ...state,
        isModal: data,
      };
    case ACCESS_TOKEN:
      return {
        ...state,
        access_token: data,
      };
    case COURSE_ID:
      return {
        ...state,
        courseId: data,
      };
    case IS_MUTED:
      return {
        ...state,
        rainIsMuted: data,
      };
    case SIDE_CONTENT_DATA:
      return {
        ...state,
        sidebarContentData: data,
      };
    case EPISODEID:
      return {
        ...state,
        episodeId: data,
      };
    case COMPLETEPROGRESS:
      return {
        ...state,
        completeProgress: data,
      };
    case VIDEOPLAY:
      return {
        ...state,
        videoPlay: data,
      };
    case VIDEOCOUNT:
      return {
        ...state,
        videoCount: data,
      };
    case BANNERTHUMB:
      return {
        ...state,
        bannerThumb: data,
      };
    case EPISODECOMPLETED:
      return {
        ...state,
        episodeCompleted: data,
      };
    case EPISODEREFRESH:
      return {
        ...state,
        episodeRefresh: data,
      };
    case BUTTONNAME:
      return {
        ...state,
        buttonName: data,
      };
    case QUIZEPISODE:
      return {
        ...state,
        quizEpisode: data,
      };
    case MENUREFRESH:
      return {
        ...state,
        menuRefresh: data,
      };
    case LANGUAGEID:
      return {
        ...state,
        languageId: data,
      };
    case SELECTLANG:
      return {
        ...state,
        selectLang: data,
      };
    case SELECTLANGJSON:
      return {
        ...state,
        selectLangJson: data,
      };
    case LANGUAGES:
      return {
        ...state,
        languages: data,
      };
    case EPISODELANGUAGES:
      return {
        ...state,
        episodeLanguages: data,
      };
    case EPISODESELECTLANGUAGE:
      return {
        ...state,
        episodeSelectLang: data,
      };
    case PROFILEUPDATE:
      return {
        ...state,
        profileUpdate: data,
      };
    case EPISODELENGTH:
      return {
        ...state,
        episodeLength: data,
      };
    case TEMPLATEID:
      return {
        ...state,
        templateId: data,
      };
    case ISACTIONDISABLED:
      return {
        ...state,
        isActionDisabled: data,
      };
    case PROFILEDATA:
      return {
        ...state,
        profileData: data,
      };
    case ONBOARDING:
      return {
        ...state,
        onBoarding: data,
      };
    case COURSEMENUDATA:
      return {
        ...state,
        courseMenuData: data,
      };
    default:
      return state;
  }
};

export default customReducer;
